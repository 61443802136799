import React from "react"

const Logo = ({ color }) => {
  return (
    <svg
      width={`${65 * 0.8}px`}
      height={`${67 * 0.8}px`}
      viewBox="0 0 65 67"
      version="1.1"
    >
      <title>jm</title>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Desktop-HD-Copy"
          transform="translate(-58.000000, -60.000000)"
          fill={color === "white" ? "#FFF" : "#F55944"}
          fill-rule="nonzero"
        >
          <g id="Group" transform="translate(61.000000, 47.000000)">
            <path
              d="M9.6,26.46 C14.28,26.46 17.22,23.58 17.22,19.92 C17.22,16.2 14.28,13.26 9.6,13.26 C4.86,13.26 1.92,16.2 1.92,19.92 C1.92,23.58 4.86,26.46 9.6,26.46 Z M2.4,79.2 C10.32,79.2 16.32,75.84 16.32,65.34 L16.32,29.16 L3,29.16 L3,65.58 C3,67.14 2.28,67.86 0.54,67.86 L-2.04,67.86 L-2.04,79.2 L2.4,79.2 Z M16.32,63 L16.32,45.06 C16.32,42.18 18.12,40.44 21.06,40.44 C23.94,40.44 25.8,42.18 25.8,45.06 L25.8,63 L39.06,63 L39.06,45.06 C39.06,42.18 40.86,40.44 43.8,40.44 C46.68,40.44 48.54,42.18 48.54,45.06 L48.54,63 L61.8,63 L61.8,43.38 C61.8,34.62 56.94,28.92 48.18,28.92 C43.2,28.92 39.36,31.74 37.08,34.98 C34.86,31.14 31.02,28.92 26.1,28.92 C21.36,28.92 18.12,31.08 16.32,33.72 L16.32,29.16 L3,29.16 L3,63 L16.32,63 Z"
              id="jm"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Logo
