import React from "react"
import styled from "@emotion/styled"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const LinkList = styled.ul`
  display: flex;
  align-items: center;
  text-decoration: none;
  list-style: none;

  li {
    &:not(:first-of-type) {
      padding-left: 1em;
    }

    a,
    a:link,
    a:visited,
    a:active,
    a:hover {
      text-decoration: none;
      color: ${props =>
        props.color === "white" ? "white" : "var(--primary-orange)"};
    }
  }
`

const Links = ({ color }) => {
  let path
  if (typeof window !== "undefined") {
    path = window.location.pathname
  }
  return (
    <LinkList color={color === "white" ? "white" : "orange"}>
      <li>
        {path === "/" ? (
          <AnchorLink to="/#projects">projects</AnchorLink>
        ) : (
          <AniLink paintDrip hex="#fff" to="/#projects">
            projects
          </AniLink>
        )}
      </li>
      <li>
        <AniLink paintDrip hex="#fff" to="/about">
          contact
        </AniLink>
      </li>
    </LinkList>
  )
}

export default Links
