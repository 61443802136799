import * as React from "react"
import styled from "@emotion/styled"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Logo from "./logo"
import Links from "./links"

const StyledHeader = styled.header`
  display: block;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
  background-color: var(--primary-orange);
`

const Nav = styled.nav`
  color: var(--primary-orange);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 1em;
  top: 0;
  width: 100%;
  background-color: var(--primary-orange);
  background-color: ${props =>
    !props.whiteBg ? "var(--primary-orange)" : "#FFF"};
  box-shadow: ${props =>
    !props.whiteBg ? "none" : "-3px 3px 24px -1px rgba(34,34,34,0.1)"};
`

const Header = ({ color, inView }) => {
  return !inView ? (
    <StyledHeader>
      <Nav whiteBg>
        <h1 className="visually-hidden">Mallette Design</h1>
        <AniLink paintDrip hex="#f55742" to="/">
          <Logo color="orange" />
        </AniLink>
        <Links color="orange" />
      </Nav>
    </StyledHeader>
  ) : (
    <StyledHeader>
      <Nav>
        <h1 className="visually-hidden">Mallette Design</h1>
        <AniLink paintDrip hex={color !== "white" ? "#f55742" : "#FFF"} to="/">
          <Logo color={color !== "white" ? "orange" : "white"} />
        </AniLink>
        <Links color={color !== "white" ? "orange" : "white"} />
      </Nav>
    </StyledHeader>
  )
}

export default Header
