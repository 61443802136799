import * as React from "react"
import PropTypes from "prop-types"

import Header from "./header/header"
import "./layout.scss"

const Layout = ({ children, headerColor, inView }) => {
  return (
    <>
      <Header color={headerColor} inView={inView} />
      <div>
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
